import {
    gsap,
    Power2
} from "gsap"

export default class Header {
    constructor(_selector = "#main-header") {
        this.container = document.querySelector(_selector)
        if (!this.container) return false
        this.container.classList.remove("hidden")
        this.y = 10
        window.addEventListener("scroll", (e) => this.onScroll())
        this.hide()
    }

    onScroll() {
        if (this.direction == "down") this.scrollDown()
        else {
            if (window.scrollY < 300) this.hide() //Siempre que hagan scrollup debemos mostrar el header
            else this.show()
        }
        this.setY(window.scrollY) //Asignamos a y el valor de window.scrollY
        // if (window.scrollY == 0 && !this.inTop) this.reset() //Si está en top 0 reseteamos
        // return 
    }

    scrollDown() {
        if (window.scrollY > this.height) {
            // if (this.inTop) this.setFixed()
            // else
            this.hide()
        }
    }

    setY(_y) {
        this.y = _y
    }

    setFixed() {
        gsap.set(this.container, {
            y: "-100%",
            opacity: 0
        })
        this.container.classList.remove("visible")
        this.container.classList.remove("in-top")
    }

    show() {
        if (this.isVisible) return false
        this.container.classList.add("visible")
        gsap.to(this.container, {
            duration: .35,
            ease: Power2.easeOut,
            y: "0%",
            opacity: 1
        })
    }

    hide() {
        if (!this.isVisible) return false
        this.container.classList.remove("visible")
        gsap.to(this.container, 0.25, {
            ease: Power2.easeOut,
            y: "-100%",
            opacity: 0
        })
    }

    reset() {
        this.container.removeAttribute("style")
        this.container.classList.add("visible")
        this.container.classList.add("in-top")
    }

    // getters

    get height() {
        let _rect = this.container.getBoundingClientRect()
        return ~~_rect.height
    }

    get direction() {
        if (this.y > window.scrollY) return "up"
        else return "down"
    }

    get isVisible() {
        return this.container.classList.contains("visible")
    }

    get inTop() {
        return this.container.classList.contains("in-top")
    }
}