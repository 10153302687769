import translations from './../../../translations/build/translations.json'
import i18next from 'i18next';

export default class I18n {
    constructor() {
        this.i18n = i18next
        this.languageCode = this.getLanguage().toLowerCase()

        if (!Object.keys(translations).includes(this.languageCode)) {
            this.languageCode = "es"
        }

        // Initialize i18n provider
        this.i18n
        .init({
            lng: this.languageCode,
            resources: translations,
          }).then(() => {
            this.updateContent()
          })

        // Add event listener to select input
        const select = document.getElementById('i18nSelect');
        if (select) {
            // set value to select
            select.value = this.languageCode

            select.addEventListener('change', ({target: { value }}) => {
                const [codeLanguage] = value.split("-")
                this.handleLocaleChange(codeLanguage.toLowerCase())
            });
        }
    }

    getLanguage() {
        const userLocale = 
            navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

        const [codeLocale] = userLocale.split("-")

        const languageFromStorage = this.getLanguageStorage()

        if (languageFromStorage) {
            return languageFromStorage
        }

        return codeLocale
    }

    updateContent() {
        let elementsToTranslate = document.querySelectorAll('[data-i18n]');
        for (let element of elementsToTranslate) {
                
            let key = element.getAttribute("data-i18n")

            if (element.localName == 'img') {
                element.setAttribute("data-src", this.i18n.t(key));
                element.src = this.i18n.t(key)
            } else  {
                element.innerHTML = this.i18n.t(key);
            }
        }

        // set random image translated
        this.setCard()
    }

    handleLocaleChange (language) {
        this.i18n.changeLanguage(language, () => {
            this.languageCode = language
            this.setLanguageStorage(language)
            this.updateContent()
        })
    }

    setLanguageStorage(language) {
        window.localStorage.setItem("i18nextLng", language)
    }

    getLanguageStorage() {
        return window.localStorage.getItem("i18nextLng")
    }

    setCard() {
        const card = document.querySelector("#dilemo-card-front")

        if (card) {
            if (this.languageCode != "es") {

                let sourceImage =  `/static/cards/dilemo-card-${Math.ceil(Math.random() * 5)}-${this.languageCode}.svg`
                card.setAttribute("data-src", sourceImage)
                card.src = sourceImage

            } else {
                
                let sourceImage =  `/static/cards/dilemo-card-${Math.ceil(Math.random() * 5)}.svg`
                card.setAttribute("data-src", sourceImage)
                card.src = sourceImage

            }
        }
    }
}