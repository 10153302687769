import { Timeline } from "gsap/gsap-core"
import inView from "in-view"

export default class Modal {
    constructor() {
        this.container = document.querySelector("#modal")
        if (!this.container) return false
        this.content = document.querySelector("#modal-content")
        this.viewed = false
        this.closeBtn = document.querySelector("#close-modal-btn")
        this.events()
        this.setTL()
    }

    setTL() {
        this.tl = new Timeline({
            paused: true
        }).to(this.container, {
            duration: .45,
            opacity: 1,
            display: "flex"
        }, 0).from("#modal-content", {
            ease: "elastic.out(1, 0.75)",
            duration: 1.85,
            opacity: 0,
            y: 100,
            rotateY: -90
        }, 0.35)
    }

    events() {
        window.addEventListener("keyup", (e) => {
            if (e.keyCode == 27 || e.which == 27) return this.hide()
        })
        this.closeBtn.addEventListener("click", () => {
            return this.hide()
        })
        this.container.addEventListener("click", () => {
            if (!this.content.matches(':hover')) return this.hide()
        })

        inView("#main-footer").on('enter', el => {
            if (!this.viewed) return this.show()
        })
    }
    
    hide() {
        if (!this.viewed) return false
        return this.tl.duration(1.65).reverse()
    }
    
    show() {
        if (this.viewed) return false
        this.viewed = true
        return this.tl.play()
    }
}