const PI_2 = Math.PI * 2

import {
    gsap,
    Power0
} from "gsap/all"

export default class Ring {
    constructor(_pos, idx) {
        this.index = idx
        this.radius = 0
        this.pos = _pos
        this.tween = null
    }

    kill() {
        if (this.tween) this.tween.kill()
    }

    restart(args) {
        this.kill()
        this.alpha = 1
        this.radius = args.radius
        this.tween = gsap.to(this, {
            ease: Power0.easeIn,
            radius: args.diagonal,
            duration: args.duration,
            alpha: 0,
            onComplete: () => {
                this.restart({
                    duration: 10,
                    radius: 0,
                    diagonal: args.diagonal
                })
            }
        })
    }

    draw(_ctx) {
        _ctx.beginPath()
        _ctx.strokeStyle = "rgba(234, 111, 192, " + this.alpha + ")"
        _ctx.arc(this.pos.x, this.pos.y, this.radius + 50, 0, PI_2, true)
        _ctx.stroke()
        _ctx.closePath()
    }
}