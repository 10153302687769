import Preloader from "./assets/js/components/preloader"
import Header from "./assets/js/components/header"
import isMobile from 'ismobilejs'
import Credits from "./assets/js/components/log"
import {
    gsap,
    Power0
} from "gsap/all"
import Modal from "./assets/js/components/modal"
import Radial from "./assets/js/components/radial"
import Stars from "./assets/js/components/stars"
import inView from "in-view"
import Blazy from "blazy"
import "./assets/scss/main.scss"
import I18n from "./assets/js/lib/i18n"

inView.threshold(.85)
class Dilemo {
    constructor() {
        this.i18n = new I18n()
        this.log = new Credits()
        this.preloader = new Preloader()
        this.bLazy = new Blazy()
        this.stars_a = new Stars("#stars", {
            mouse: true
        })
        this.stars_b = new Stars("#dilemo-coins", {
            mouse: false
        })
        this.stars_c = new Stars("#dilemo-cards", {
            mouse: false
        })
        if (isMobile(window.navigator).any) {
            this.header = new Header("#main-header")
            const store_links = document.querySelectorAll(".store-link")
            if (isMobile(window.navigator).apple.device) {
                store_links.forEach(el => {
                    el.setAttribute("href", APP_STORE)
                    el.classList.add("app-store-link")
                })
            } else {
                store_links.forEach(el => {
                    el.setAttribute("href", PLAY_STORE)
                    el.classList.add("google-play-link")
                })
            }
        }
        const radial = document.querySelector("#radial")
        this.radial = null
        if (radial) {
            this.radial = new Radial("#radial")
        }
        this.modal = new Modal()
        this.card_visible = false
        const card = document.querySelector('#card-wrapper')
        if (card) {
            let options = {
                root: null,
                rootMargin: '-15px',
                threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
            }
            this.prevRatio = 0
            let observer = new IntersectionObserver((entries, observer) => this.intersect(entries, observer), options)
            observer.observe(card)

            inView("#you-can").on('enter', _el => {
                this.card_visible = true
            }).on("exit", () => {
                this.card_visible = false
            })
        }

        this.setCard()
        this.setInview()
    }

    setCard() {
        const card = document.querySelector("#dilemo-card-front")
        if (card) return card.setAttribute("src", "/static/cards/dilemo-card-" + Math.ceil(Math.random() * 5) + ".svg")
    }

    intersect(entries, observer) {
        entries.forEach((entry) => {
            if (this.card_visible) return false
            gsap.to("#dilemo-card-front", {
                ease: Power0.linear,
                duration: .01,
                y: 100 - (entry.intersectionRatio * 100) + "%"
            })
            gsap.to("#card-container", {
                ease: Power0.linear,
                duration: .01,
                y: "-" + ((-entry.intersectionRatio * 100) + 100) + "%"
            })
            this.prevRatio = entry.intersectionRatio;
        })
    }

    setInview() {
        inView("#dilemo-refe").on('enter', _el => {
            gsap.to("#card-wrapper", {
                duration: .35,
                opacity: 0
            })
            gsap.to("#hex-face-a", {
                duration: .35,
                opacity: 1
            })
            gsap.to("#hex-face-b", {
                duration: .35,
                opacity: 0
            })
        }).on("exit", _el => {
            gsap.to("#card-wrapper", {
                duration: .35,
                opacity: 1
            })
            gsap.to("#hex-face-a", {
                duration: .35,
                opacity: 0
            })
            gsap.to("#hex-face-b", {
                duration: .35,
                opacity: 1
            })
        })
        inView(".inview-item").on('enter', _el => {
            if (!_el.classList.contains("inview")) {
                _el.classList.add("inview")
                gsap.to(_el, {
                    ease: "elastic.out(1, 0.85)",
                    duration: 1.5,
                    opacity: 1,
                    // scaleX: 1,
                    // scaleY: 1,
                    y: 0,
                    // z: 0,
                    force3D: true
                })
            }
        })
    }

    loaded() {
        this.preloader.hide()
    }
}

const _app = new Dilemo()
window.addEventListener("load", () => {
    _app.loaded()
})