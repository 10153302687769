export default class Credits {
    constructor() {
        this.producer()
        this.craft()
    }

    producer() {
        const args = [
            "%c ✨ Dilemo por %c  %c 🐷 https://pictoline.com ",
            'background: #384af9; padding:5px 0; color: #54cdfc',
            'background: #e846c5; padding:5px 0;',
            'color: #0f0c0d; background: #030307; padding:5px 0;'
        ];
        console.log.apply(console, args)
    }

    craft() {
        const args = [
            "%c ✏️💻 Design & Code by %c 🔥 https://c.anvas.co",
            'background: #a9dc76; color: #0f0c0d; padding:5px 0;',
            'background: #ff66a5; color: #0f0c0d; padding: 5px 15px 5px 5px;'
        ]
        console.log.apply(console, args)
    }
}