import { gsap, Power2 } from "gsap/all"
export default class Preloader {
    constructor() {
        this.href = ""
        this.setTL()
        this.events()
    }

    events() {
        const links = document.querySelectorAll(".change-page")
        links.forEach(el => {
            el.addEventListener("click", (e) => {
                e.preventDefault()
                this.href = e.target.getAttribute("href")
                return this.show()
            })
        })
    }

    setTL() {
        this.tl = new gsap.timeline({
            paused: true,
            delay: 1,
            onStart: () => {
                window.scroll(0,0)
            },
            onReverseComplete: () => {
                window.location.href = this.href 
            }
        }, 0).to("#preloader", {
            ease: Power2.easeOut,
            duration: .65,
            opacity: 0,
            display: "none"
        }, 0).to("#preloader img", {
            ease: Power2.easeOut,
            duration: .65,
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            y: 30
        }, .15)
        .from("#app", {
            ease: Power2.easeOut,
            duration: .65,
            opacity: 0,
            y: 100
        }, .15)
        .from(".intro-item", {
            ease: Power2.easeOut,
            stagger: .035,
            duration: .85,
            opacity: 0,
            y: 150,
            scaleX: 0,
            scaleY: 0
        }, .15)
    }

    hide() {
        return this.tl.play()
    }

    show() {
        return this.tl.duration(1).reverse()
    }
}