import { gsap, Power2 } from "gsap/all"
const COLORS = ["#d1ff82", "#7dfffd"]
export default class Star {
    constructor(_pos) {
        this.pos = {
            x: _pos.x || 0,
            y: _pos.y || 0
        }
        this.radio = 10
        this.live = true
        this.scale = 0
        this.fillStyle = "#FFF"
        this.friction = .075
        this.rotation = Math.random() * Math.PI
        this.start()
    }

    start() {
        gsap.to(this, {
            ease: Power2.easeInOut,
            duration: 3,
            rotation: this.rotation + 30
        })
        gsap.to(this, {
            ease: Power2.easeOut,
            duration: .5,
            fillStyle: "#7dfffd"
        })
        gsap.to(this, {
            ease: Power2.easeOut,
            duration: 1,
            delay: Math.random() + 1,
            fillStyle: "#d1ff82"
        })
        gsap.fromTo(this, {
            ease: Power2.easeIn,
            duration: 1.5,
            scale: Math.random() * 5,
        }, {
            ease: Power2.easeOut,
            duration: 1.5,
            scale: 0,
            onComplete: () => {
                this.live = false
            }
        })
    }

    update(_pos) {
        let _x = _pos.x
        let _y = _pos.y
        this.pos.x += (_x - this.pos.x) * this.friction
        this.pos.y += (_y - this.pos.y) * this.friction
    }

    isDied() {
        return !this.live
    }

    draw(ctx) {
        
        ctx.save();
        ctx.fillStyle = this.fillStyle
        
        ctx.translate(this.pos.x, this.pos.y)
        ctx.scale(this.scale, this.scale)
        ctx.rotate(this.rotation)
        ctx.beginPath()
        ctx.moveTo(4.98,4.16)
        ctx.bezierCurveTo(2.62,1.8,2.63,-2.05,5.01,-4.43)
        ctx.bezierCurveTo(5.09,-4.51,5.13,-4.6,5.13,-4.71)
        ctx.bezierCurveTo(5.13,-4.81,5.09,-4.91,5.02,-4.99)
        ctx.bezierCurveTo(4.86,-5.14,4.61,-5.14,4.45,-4.98)
        ctx.bezierCurveTo(3.3,-3.83,1.78,-3.18,0.15,-3.18)
        ctx.bezierCurveTo(-1.47,-3.17,-3,-3.8,-4.15,-4.94)
        ctx.bezierCurveTo(-4.3,-5.09,-4.56,-5.09,-4.72,-4.93)
        ctx.bezierCurveTo(-4.8,-4.85,-4.84,-4.75,-4.84,-4.65)
        ctx.bezierCurveTo(-4.84,-4.5,-4.8,-4.45,-4.73,-4.37)
        ctx.bezierCurveTo(-3.57,-3.23,-2.95,-1.7,-2.95,-0.08)
        ctx.bezierCurveTo(-2.96,1.55,-3.6,3.07,-4.75,4.22)
        ctx.bezierCurveTo(-4.83,4.3,-4.87,4.4,-4.87,4.51)
        ctx.bezierCurveTo(-4.87,4.61,-4.83,4.71,-4.76,4.79)
        ctx.bezierCurveTo(-4.6,4.94,-4.35,4.94,-4.18,4.78)
        ctx.bezierCurveTo(-1.8,2.4,2.05,2.39,4.41,4.74)
        ctx.bezierCurveTo(4.49,4.82,4.59,4.85,4.69,4.85)
        ctx.bezierCurveTo(4.79,4.85,4.9,4.81,4.98,4.73)
        ctx.bezierCurveTo(5.05,4.65,5.09,4.54,5.1,4.44)
        ctx.bezierCurveTo(5.1,4.34,5.06,4.24,4.98,4.16)

        ctx.closePath()
        ctx.fill()
        ctx.restore()
    }
}