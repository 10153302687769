const PX_RATIO = window.devicePixelRatio
import Canvas from "../lib/canvas"
import { debounce } from "throttle-debounce"
import isMobile from 'ismobilejs'
import Ring from "./ring"
Math.dist = (a, b) => {
    return ~~Math.sqrt(Math.pow((a.x - b.x), 2) + Math.pow((a.y - b.y), 2))
}
Math.map = (n, start, stop, start2, stop2) => {
    const newval = (n - start) / (stop - start) * (stop2 - start2) + start2
    return newval
}

export default class Radial extends Canvas {
    constructor(containerId) {
        super(containerId)
        this.gradient = document.querySelector("#bg-card-section")
        this.y = 0
        this.total_rings = 10
        this.rings = []
        this.onResize()
        this.animate()
    }

    setStyle() {
        this.canvas.style.position = "absolute"
        this.canvas.style.width = "100%"
        this.canvas.style.height = "100%"
        this.canvas.style.objectFit = "cover"
        this.canvas.style.objectPosition = "center"
    }

    setup() {
        this.rings = []
        for (let i = 0; i < this.total_rings; i++) {
            let radius = (this.diagonal / this.total_rings) * i
            let ring = new Ring(this.center, i)
            let duration = this.total_rings - i
            this.rings.push(ring)
            ring.restart({
                radius: radius,
                duration: duration,
                diagonal: this.diagonal
            })
        }
        const _resize = debounce(150, () => this.onResize())
        if (isMobile(window.navigator).any) {
            window.addEventListener("orientationchange", _resize, false)
        } else {
            window.addEventListener("resize", _resize, false)
        }
    }
    
    setSize() {
        [this.width, this.height] = [this.container.getBoundingClientRect().width, window.innerHeight]
        // this.canvas.style.width = this.width + "px"
        // this.canvas.style.height = this.height + "px"
        // this.canvas.style.width = "auto"
        // this.canvas.style.height = "100%"
        this.canvas.width = this.width * PX_RATIO
        this.canvas.height = this.height * PX_RATIO
    }

    get maxScrollY() {
        return document.body.getBoundingClientRect().height - window.innerHeight
    }

    onResize() {
        this.setSize()
        this.center = {
            x: this.canvas.width / 2,
            y: this.canvas.height / 2
        }
        //
        this.diagonal = Math.dist({
            x: 0,
            y: 0
        }, this.center)
        for (let i = 0; i < this.rings.length; i++) this.rings[i].kill()
        this.lineWidth = this.diagonal / 18
        this.setup()
    }

    render() {
        this.y = window.scrollY
        this.clear()
        this.context.lineWidth = this.lineWidth
        for (let i = 0; i < this.rings.length; i++) {
            this.rings[i].draw(this.context)
        }
        if (this.gradient) this.updateBG()
    }

    updateBG() {
        this.y += (window.scrollY - this.y) * .1
        const _deg = Math.map(this.y, 0, (document.body.getBoundingClientRect().height - window.innerHeight), -10, 90)
        this.gradient.style.filter = `hue-rotate(${_deg}deg)`
    }

}