import {
    throttle,
    debounce
} from "throttle-debounce"
import {
    gsap
} from "gsap"
import Star from "./star"
import Canvas from "../lib/canvas"
import isMobile from 'ismobilejs'
import { _numExp } from "gsap/gsap-core"
const PX_RATIO = window.devicePixelRatio
export default class Stars extends Canvas {
    constructor(_containerSelector, args = {}) {
        super(_containerSelector)
        this.mouse = args.mouse || false
        this.stars = []
        this.pos = {
            x: 0,
            y: 0
        }
        if (this.container) {
            this.events()
            this.animate()
        }
    }

    events() {
        const _resize = debounce(150, () => this.onResize())
        if (isMobile(window.navigator).any) {
            window.addEventListener("orientationchange", _resize, false)
        } else {
            window.addEventListener("resize", _resize, false)
        }
        
        if (this.mouse) {
            const onMouseMove = throttle(30, (e) => this.onMouseMove(e))
            window.addEventListener("pointermove", (e) => onMouseMove(e), false)
    
            const addStar = throttle(100, (e) => this.addStar(e))
            window.addEventListener("pointermove", (e) => addStar(e), false)
        } else {
            this.starsInterval()
        }
        this.onResize()
    }

    starsInterval() {
        gsap.delayedCall(.3, () => {
            this.pos = {
                x: 20 + (Math.random() * (this.width - 40)),
                y: 20 + (Math.random() * (this.height - 40))
            }
            this.starsInterval()
            this.addStar()
        })
    }

    onResize() {
        this.setSize()
        this.center = {
            x: this.canvas.width / 2,
            y: this.canvas.height / 2
        }
    }

    onMouseMove(e) {
        this.pos = {
            x: e.clientX,
            y: e.clientY
        }
    }
   
    addStar() {
        let _star = new Star({
            x: this.pos.x + (Math.sin(this.time - this.pos.x + this.pos.y) * 30),
            y: this.pos.y - (Math.cos(this.time + this.pos.x / this.pos.y) * 30)
        })
        this.stars.push(_star)
    }

    setSize() {
        [this.width, this.height] = [this.container.getBoundingClientRect().width, this.container.getBoundingClientRect().height]
        this.canvas.style.width = this.width + "px"
        this.canvas.style.height = this.height + "px"
        this.canvas.width = this.width * PX_RATIO
        this.canvas.height = this.height * PX_RATIO
    }

    render() {
        this.time = new Date().getTime() * 0.0001;
        this.clear()
        for (let i = 0; i < this.stars.length; i++) {
            let _star = this.stars[i]
            // _star.update(this.pos)
            _star.draw(this.context)
            if (_star.isDied()) this.stars.splice(0, i)
        }
    }
}