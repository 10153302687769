const PX_RATIO = Math.min(window.devicePixelRatio, 2)
export default class Canvas {
    constructor(containerId) {
        this.container = document.querySelector(containerId)
        if (!this.container) return false
        //
        this.canvas = document.createElement("canvas")
        this.container.appendChild(this.canvas)
        this.context = this.canvas.getContext("2d")
        this.setStyle()
    }
    
    setStyle() {
        this.canvas.style.position = "absolute"
        this.canvas.style.left = 0
        this.canvas.style.top = 0
    }

    height() {
        return this.container.getBoundingClientRect().height
    }
    
    width() {
        return this.container.getBoundingClientRect().width
    }

    setSize() {
        this.canvas.style.width = this.width + "px"
        this.canvas.style.height = this.height + "px"
        this.canvas.width = this.width * PX_RATIO
        this.canvas.height = this.height * PX_RATIO
    }

    animate() {
        requestAnimationFrame(() => this.animate())
        this.render()
    }

    clear() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    stroke(_color) {
        this.context.strokeStyle = _color
        this.context.stroke()
    }

    background(_fill) {
        if (_fill === undefined) return "El color de background es requerido"
        this.context.fillStyle = _fill
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }
}